import axios from 'axios'
import store from '../store'
import { Modal, message } from 'ant-design-vue'

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API_OESE + '/api', // 正式
  baseURL: process.env.VUE_APP_BASE_API_SMARTTOOL + '/api/',
  timeout: 60 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json',
  withCredentials: true
})

service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (store.getters.token) {
      config.headers['token'] = store.getters.token
    }
    if (config.method === 'get') {
      if (config.params.config) {
        Object.assign(config, config.params.config)
        delete config.params.config
      }
    }
    if (config.params) {
      let params = config.params
      for (let k in params) {
        if (params[k]) { params[k] = encodeURI(params[k]) }
      }
      config.params = params
    }
    return config
  },
  error => {
    // Do something with request error

    console.log('bugMSG:', error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  res => {
    if (!res.config.simplify) {
      return res.data
    }
    if (res.data.status === 0) {
      return res.data.data
    } else {
      message.error('错误信息：' + res.data.msg)
      return Promise.reject(res.data.msg)
    }
  },
  async ({ message = '', config = { silent: false }, response }) => {
    // 对不用报错的接口进行配置
    if (config.silent) {
      return
    }
    if (response.data.msg) {
      Modal.error({ title: '请求出错了 ', content: response.data.msg, okText: '确定' })
    } else {
      Modal.error({ title: '请求出错了 ', content: message, okText: '确定' })
    }
    return Promise.reject(new Error('请求出错'))
  }
)

const post = url => (data, config = { simplify: true }) => service.post(url, data, config)
const get = url => (config = { simplify: true }) => service.get(url, { simplify: true, ...config })

export { post, get }
